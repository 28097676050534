.button {
  background-color: blue;
  border-color: blue;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 15px;
  margin: 10px 0px;
  cursor: pointer;
}
